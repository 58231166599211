@use 'dimensions' as dim;
@use 'fonts' as fonts;
@use 'colours' as colours;

@mixin icon-size($size) {
  font-size: $size;
  width: $size;
  height: $size;
}

// Style to apply to mat-card in screens that have a card as unique-main holder
@mixin eln-card-screen-holder {
  display: flex;
  flex-direction: column;
  width: dim.$content-max-width;
  max-height: dim.$card-screen-max-height;
  flex: 1;
  margin: dim.$content-margin 0;
}

@mixin eln-screen-loader {
  margin: auto;
}

@mixin eln-card {
  border-radius: 30px !important;
  padding: dim.$eln-card-padding !important;
}

@mixin eln-btn {
  border-radius: dim.$eln-card-radius !important;
  display: flex !important;
  flex-flow: row nowrap !important;
  align-items: center !important;
  justify-content: center !important;
  height: dim.$button-height-medium;
  max-width: dim.$button-max-width !important;
  width: 100%;

  mat-spinner {
    width: 32px !important;
    height: 32px !important;
  }
}

@mixin eln-btn-big {
  @include eln-btn;

  height: dim.$button-height-big;

  mat-spinner {
    width: 38px !important;
    height: 38px !important;
  }
}

@mixin eln-btn-small {
  @include eln-btn;

  height: dim.$button-height-small;

  mat-spinner {
    width: 28px !important;
    height: 28px !important;
  }
}

@mixin eln-btn-disabled-theme {
  background-color: colours.$color-disabled-button;
  color: colours.$color-disabled-button-text;
  box-shadow: none;
  cursor: default;
}

@mixin eln-tag {
  border-radius: 5px !important;
  display: flex !important;
  flex-flow: row nowrap !important;
  align-items: center !important;
  justify-content: center !important;
  height: dim.$tag-height-medium;
  font-size: 14px;
  font-weight: 600;
  padding: 0 8px;
  color: colours.$color-primary-on-top;
  border: none;
}

@mixin eln-tag-big {
  @include eln-tag;

  height: dim.$tag-height-big;
}

@mixin eln-tag-small {
  @include eln-tag;

  font-size: 12px;
  height: dim.$tag-height-small;
}

@mixin eln-carousel-holder {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 0;
  }

  & > div {
    @include eln-carousel-item-holder;
  }
}

@mixin eln-carousel-item-holder {
  width: 100%;
  scroll-snap-align: start;
  flex-shrink: 0;
}
