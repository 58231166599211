@use 'styles/theme';
@use 'fonts' as fonts;
@use 'dimensions' as dim;
@use 'common' as common;
@use 'colours' as colours;

/*********** Start - Basic styles ***********/
body,
html,
eln-root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}

.white-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  img {
    max-width: 50vw;
  }
}

button {
  &.eln-btn {
    @include common.eln-btn;
  }

  &.eln-btn-big {
    @include common.eln-btn-big;
  }

  &.eln-btn-small {
    @include common.eln-btn-small;
  }

  // Button disabled colors applied by Material Design
  &.eln-btn:disabled,
  &.eln-btn-big:disabled,
  &.eln-btn-small:disabled {
    --mdc-protected-button-disabled-container-color: #{colours.$color-disabled-button};
    --mdc-protected-button-disabled-label-text-color: #{colours.$color-disabled-button-text};
  }

  // Class "disabled" for buttons that we apply around the app
  &.eln-btn.disabled,
  &.eln-btn-big.disabled,
  &.eln-btn-small.disabled {
    @include common.eln-btn-disabled-theme;
  }
}

mat-card {
  @include common.eln-card;
}

mat-spinner {
  &.eln-screen-loader {
    @include common.eln-screen-loader;
  }
}

// We cannot have blue links or text decoration.
a, a:visited {
  color: #232323;
  text-decoration: none;
}

// Make all tabs inside a tab group to occupy the same space
mat-tab-group {
  .mat-mdc-tab {
    flex: 1;
  }
}

// All Material Dividers will have the same colour
mat-divider {
  color: colours.$color-divider;
}

// All mat-tooltips will have "secondary" background and centered text
mat-tooltip-component {
  --mdc-plain-tooltip-container-color: #{colours.$color-secondary};

  * {
    text-align: center !important;
  }
}

// All Material dialogs will have rounded borders like mat-cards
mat-dialog-container {
  --mdc-dialog-container-shape: #{dim.$eln-card-radius};
}

mat-form-field {
  // All Material Form Fields will have transparent background
  --mdc-filled-text-field-container-color: transparent;

  // Bottom line, when field is not active, should not be black
  --mdc-filled-text-field-active-indicator-color: #{colours.$color-divider};
}

// When we open a Material Selector, the box should be opaque, not transparent
div {
  --mat-select-panel-background-color: #{colours.$color-app-background};
  --mat-autocomplete-background-color: #{colours.$color-app-background};
}

/*********** End - Basic styles ***********/

.eln-no-flippers::-webkit-outer-spin-button,
.eln-no-flippers::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
